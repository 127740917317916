import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import SignUpButton from "../components/SignUpButton";
import ScrumMate from "../images/logo-text.inline.svg";
import Image from "../components/image";

const BlogSignUpFooter = () => {
    return (
        <div className={"mt-32"}>
            <h1 className={"text-center text-xl font-extrabold"}>Sign up for a 15-day free trial</h1>
            <div className={"text-center pt-8"}>
                <SignUpButton>Sign up</SignUpButton>
                <div className={"max-w-lg mx-auto text-center mt-8"}>
                    <ScrumMate style={{ width: 130 }} className={"fill-sm-orange-400 mx-auto mb-8"} />
                    <Image imgName={"Rocket-sign-up-img"} className={"w-full"} />
                </div>
            </div>
        </div>
    );
};

export default function Template({ data, location }) {
    const { markdownRemark } = data; // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark;
    return (
        <div>
            <SEO
                breadcrumb={[{ name: "Blog", pathname: "/blog/" }, frontmatter.title]}
                meta_title={frontmatter.meta_title}
                title={frontmatter.title}
                description={frontmatter.meta_description || frontmatter.teaser}
                image={frontmatter.social_photo.publicURL}
                location={location}
            />
            <div className={"blog-post"}>
                <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
                <div className={"px-16 py-0 text-right text-gray-700 text-sm mx-auto"} style={{ maxWidth: 964 }}>
                    Published on {frontmatter.date}
                </div>
            </div>
            <BlogSignUpFooter />
        </div>
    );
}
export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                meta_title
                meta_description
                teaser
                social_photo {
                    publicURL
                }
            }
        }
    }
`;
