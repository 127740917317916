import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({ imgName, style, className, alt = "", ...restProps }) => {
    const data = useStaticQuery(graphql`{
  images: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
    edges {
      node {
        relativePath
        name
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
}
`);

    const image = data.images.edges.find(n => {
        return n.node.name === imgName;
    });
    if (!image) {
        return null;
    }

    return (
        <GatsbyImage
            image={image.node.childImageSharp.gatsbyImageData}
            style={style}
            className={className}
            alt={alt}
            {...restProps} />
    );
};

export default Image;
